import { db, auth } from '../firebase/config';
import { doc, getDoc, setDoc, collection, addDoc, updateDoc } from 'firebase/firestore';
import type { Settings } from './types';

export const fetchSettings = async (): Promise<Settings | null> => {
  try {
    if (!auth.currentUser) {
      throw new Error('No user logged in');
    }

    // Get shared settings from the correct path: settings/shared_settings
    const settingsRef = doc(db, 'settings', 'shared_settings');
    const settingsSnap = await getDoc(settingsRef);

    if (!settingsSnap.exists()) {
      // Return default settings if none exist
      return {
        company: {
          companyName: '',
          address: '',
          city: '',
          state: '',
          zipCode: '',
          website: '',
          phone: '',
          email: '',
          basicLogo: null,
          coverBackground: null,
          proposalBase: [],
          invoiceBase: null,
        },
        personal: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          title: '',
        },
        prices: {},
      };
    }

    return settingsSnap.data() as Settings;
  } catch (error) {
    console.error('Error fetching settings:', error);
    throw error;
  }
};

export const saveSettings = async (settings: Partial<Settings>): Promise<void> => {
  try {
    if (!auth.currentUser) {
      throw new Error('No user logged in');
    }

    const settingsRef = doc(db, 'settings', 'shared_settings');
    const currentSettings = await fetchSettings();

    await setDoc(settingsRef, {
      ...currentSettings,
      ...settings,
    }, { merge: true });
  } catch (error) {
    console.error('Error saving settings:', error);
    throw error;
  }
};

export const ensureUserDocument = async (userId: string, email?: string, displayName?: string): Promise<boolean> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    const userData = {
      email: email || null,
      displayName: displayName || null,
      updatedAt: new Date().toISOString(),
      ...(userSnap.exists() ? {} : { createdAt: new Date().toISOString() })
    };

    await setDoc(userRef, userData, { merge: true });
    return true;
  } catch (error) {
    console.error('Error ensuring user document:', error);
    return false;
  }
};

export const createProposal = async (proposalData: any): Promise<string> => {
  try {
    if (!auth.currentUser) {
      throw new Error('No user logged in');
    }

    // Ensure date is stored with local timezone
    const date = proposalData.date ? new Date(proposalData.date + 'T00:00:00').toISOString().split('T')[0] : null;

    const proposalsCollection = collection(db, 'proposals');
    const docRef = await addDoc(proposalsCollection, {
      ...proposalData,
      date, // Store normalized date
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });

    return docRef.id;
  } catch (error) {
    console.error('Error creating proposal:', error);
    throw error;
  }
};

export const updateProposal = async (proposalId: string, proposalData: any): Promise<void> => {
  try {
    if (!auth.currentUser) {
      throw new Error('No user logged in');
    }

    // Ensure date is stored with local timezone
    const date = proposalData.date ? new Date(proposalData.date + 'T00:00:00').toISOString().split('T')[0] : null;

    const proposalRef = doc(db, 'proposals', proposalId);
    await updateDoc(proposalRef, {
      ...proposalData,
      date, // Store normalized date
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error('Error updating proposal:', error);
    throw error;
  }
};

export const fetchProposal = async (proposalId: string): Promise<any> => {
  try {
    if (!auth.currentUser) {
      throw new Error('No user logged in');
    }

    const proposalRef = doc(db, 'proposals', proposalId);
    const proposalSnap = await getDoc(proposalRef);

    if (!proposalSnap.exists()) {
      throw new Error('Proposal not found');
    }

    return proposalSnap.data();
  } catch (error) {
    console.error('Error fetching proposal:', error);
    throw error;
  }
};
